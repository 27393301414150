@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "futura";
    src: url("../public/assets/fonts/Futura.ttf");
}

@font-face {
    font-family: "DMSans";
    src: url("../public/assets/fonts/DMSans.ttf");
}

h1,
h2,
h3 {
    font-family: "futura";
}

html,
body {
    font-family: "DMSans";
}

@media screen and (max-width:700px) {
    :root {
        font-size: 13px;
    }
}

@media screen and (min-width:700px) {
    .container {
        width: 90%;
    }
}

.container {
    margin: auto;
    width: 96%;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-image: linear-gradient(#1bb7ff, #0053a5);
}

.dropdown-body {
    display: none;
    z-index: 201;
}

.dropdown-opener:hover>.dropdown-body {
    display: block;
}

input[type="file"]::file-selector-button {
    background-color: #0053a5;
    color: white;
    border: none;
    outline: none;
    border-radius: 0.2rem;
    font-size: 0.75rem;
    padding: 0.2rem 0.5rem;
    font-family: "DMSans";
}

.course-detail {
    height: 30rem;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../public/assets/imgs/course-detail.jpg"); */
    background-size: cover;
    background-attachment: fixed;
}

/* Example CSS for styling content from the Quill editor */

/* Container for the editor content */
.quill-container {
    max-width: 80%;
    /* Adjust to your preference */
    margin: 0 auto;
}

/* Styling for headings */
.quill-container h1 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
}

.quill-container h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.quill-container h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

/* Styling for paragraphs */
.quill-container p {
    margin-bottom: 1rem;
}

/* Styling for lists */
.quill-container ul,
.quill-container ol {
    margin-bottom: 1rem;
    list-style-type: disc !important;
    list-style-position: inside;
}

.quill-container li {
    margin-bottom: 0.5rem;
}

/* Styling for links */
.quill-container a {
    color: blue;
    text-decoration: underline;
}

.quill-container a:hover {
    color: darkblue;
}

/* Styling for inline code */
.quill-container code {
    background-color: #f4f4f4;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-family: monospace;
}

/* Styling for blockquote */
.quill-container blockquote {
    margin-left: 0;
    padding-left: 1rem;
    border-left: 2px solid #ccc;
}

/* Styling for images */
.quill-container img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
}

.grid-mid {
    grid-column: 1 / -1;
}